/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  CheckboxField,
  Flex,
  Grid,
  SelectField,
  TextAreaField,
  TextField,
  useTheme,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { createOverallResults } from "../graphql/mutations";
const client = generateClient();
export default function OverallResultsCreateForm(props) {
  const {
    clearOnSuccess = true,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const { tokens } = useTheme();
  const initialValues = {
    riderFullName: "",
    horseName: "",
    competition: "",
    date: "",
    discipline: "",
    ageGroup: "",
    class: "",
    eventScoreTime: "",
    result: "",
    Field0: false,
  };
  const [riderFullName, setRiderFullName] = React.useState(
    initialValues.riderFullName
  );
  const [horseName, setHorseName] = React.useState(initialValues.horseName);
  const [competition, setCompetition] = React.useState(
    initialValues.competition
  );
  const [date, setDate] = React.useState(initialValues.date);
  const [discipline, setDiscipline] = React.useState(initialValues.discipline);
  const [ageGroup, setAgeGroup] = React.useState(initialValues.ageGroup);
  const [class1, setClass1] = React.useState(initialValues.class);
  const [eventScoreTime, setEventScoreTime] = React.useState(
    initialValues.eventScoreTime
  );
  const [result, setResult] = React.useState(initialValues.result);
  const [Field0, setField0] = React.useState(initialValues.Field0);
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    setRiderFullName(initialValues.riderFullName);
    setHorseName(initialValues.horseName);
    setCompetition(initialValues.competition);
    setDate(initialValues.date);
    setDiscipline(initialValues.discipline);
    setAgeGroup(initialValues.ageGroup);
    setClass1(initialValues.class);
    setEventScoreTime(initialValues.eventScoreTime);
    setResult(initialValues.result);
    setField0(initialValues.Field0);
    setErrors({});
  };
  const validations = {
    riderFullName: [{ type: "Required" }],
    horseName: [],
    competition: [{ type: "Required" }],
    date: [{ type: "Required" }],
    discipline: [],
    ageGroup: [],
    class: [{ type: "Required" }],
    eventScoreTime: [],
    result: [
      { type: "Required" },
      {
        type: "EndWith",
        strValues: ["st", "nd", "rd", "th"],
        validationMessage:
          'The value must end with "st" or "nd" or "rd" or "th"',
      },
      {
        type: "StartWith",
        strValues: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
        validationMessage:
          'The value must start with "1" or "2" or "3" or "4" or "5" or "6" or "7" or "8" or "9" or "10"',
      },
    ],
    Field0: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap={tokens.space.medium.value}
      columnGap={tokens.space.medium.value}
      padding={tokens.space.large.value}
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          riderFullName,
          horseName,
          competition,
          date,
          discipline,
          ageGroup,
          class: class1,
          eventScoreTime,
          result,
          Field0,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: createOverallResults.replaceAll("__typename", ""),
            variables: {
              input: {
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
          if (clearOnSuccess) {
            resetStateValues();
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "OverallResultsCreateForm")}
      {...rest}
    >
      <TextField
        label="Rider"
        descriptiveText="Full name"
        isRequired={true}
        isReadOnly={false}
        value={riderFullName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName: value,
              horseName,
              competition,
              date,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime,
              result,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.riderFullName ?? value;
          }
          if (errors.riderFullName?.hasError) {
            runValidationTasks("riderFullName", value);
          }
          setRiderFullName(value);
        }}
        onBlur={() => runValidationTasks("riderFullName", riderFullName)}
        errorMessage={errors.riderFullName?.errorMessage}
        hasError={errors.riderFullName?.hasError}
        {...getOverrideProps(overrides, "riderFullName")}
      ></TextField>
      <TextField
        label="Horse"
        descriptiveText="Name"
        isRequired={false}
        isReadOnly={false}
        value={horseName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName: value,
              competition,
              date,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime,
              result,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.horseName ?? value;
          }
          if (errors.horseName?.hasError) {
            runValidationTasks("horseName", value);
          }
          setHorseName(value);
        }}
        onBlur={() => runValidationTasks("horseName", horseName)}
        errorMessage={errors.horseName?.errorMessage}
        hasError={errors.horseName?.hasError}
        {...getOverrideProps(overrides, "horseName")}
      ></TextField>
      <TextField
        label="Competition Name"
        descriptiveText="As shown on nominate"
        isRequired={true}
        isReadOnly={false}
        value={competition}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName,
              competition: value,
              date,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime,
              result,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.competition ?? value;
          }
          if (errors.competition?.hasError) {
            runValidationTasks("competition", value);
          }
          setCompetition(value);
        }}
        onBlur={() => runValidationTasks("competition", competition)}
        errorMessage={errors.competition?.errorMessage}
        hasError={errors.competition?.hasError}
        {...getOverrideProps(overrides, "competition")}
      ></TextField>
      <TextField
        label="Date of Competition"
        descriptiveText="Tap box to reveal calendar on mobile"
        isRequired={true}
        isReadOnly={false}
        type="date"
        value={date}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName,
              competition,
              date: value,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime,
              result,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.date ?? value;
          }
          if (errors.date?.hasError) {
            runValidationTasks("date", value);
          }
          setDate(value);
        }}
        onBlur={() => runValidationTasks("date", date)}
        errorMessage={errors.date?.errorMessage}
        hasError={errors.date?.hasError}
        {...getOverrideProps(overrides, "date")}
      ></TextField>
      <Grid
        columnGap="inherit"
        rowGap="inherit"
        templateColumns="repeat(2, auto)"
        {...getOverrideProps(overrides, "RowGrid4")}
      >
        <SelectField
          label="Discipline"
          descriptiveText=""
          placeholder="Please select an option"
          isDisabled={false}
          value={discipline}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                riderFullName,
                horseName,
                competition,
                date,
                discipline: value,
                ageGroup,
                class: class1,
                eventScoreTime,
                result,
                Field0,
              };
              const result = onChange(modelFields);
              value = result?.discipline ?? value;
            }
            if (errors.discipline?.hasError) {
              runValidationTasks("discipline", value);
            }
            setDiscipline(value);
          }}
          onBlur={() => runValidationTasks("discipline", discipline)}
          errorMessage={errors.discipline?.errorMessage}
          hasError={errors.discipline?.hasError}
          {...getOverrideProps(overrides, "discipline")}
        >
          <option
            children="Campdrafting"
            value="Campdrafting"
            {...getOverrideProps(overrides, "disciplineoption0")}
          ></option>
          <option
            children="Combined Training"
            value="Combined Training"
            {...getOverrideProps(overrides, "disciplineoption1")}
          ></option>
          <option
            children="Cross-Country"
            value="Cross-Country"
            {...getOverrideProps(overrides, "disciplineoption2")}
          ></option>
          <option
            children="Dressage"
            value="Dressage"
            {...getOverrideProps(overrides, "disciplineoption3")}
          ></option>
          <option
            children="Eventing"
            value="Eventing"
            {...getOverrideProps(overrides, "disciplineoption4")}
          ></option>
          <option
            children="Gymkhana"
            value="Gymkhana"
            {...getOverrideProps(overrides, "disciplineoption5")}
          ></option>
          <option
            children="Showing"
            value="Showing"
            {...getOverrideProps(overrides, "disciplineoption6")}
          ></option>
          <option
            children="Showjumping"
            value="Showjumping"
            {...getOverrideProps(overrides, "disciplineoption7")}
          ></option>
          <option
            children="Sporting"
            value="Sporting"
            {...getOverrideProps(overrides, "disciplineoption8")}
          ></option>
          <option
            children="Stockmans Challenge"
            value="Stockmans Challenge"
            {...getOverrideProps(overrides, "disciplineoption9")}
          ></option>
          <option
            children="Team Penning"
            value="Team Penning"
            {...getOverrideProps(overrides, "disciplineoption10")}
          ></option>
          <option
            children="Tetrathlon"
            value="Tetrathlon"
            {...getOverrideProps(overrides, "disciplineoption11")}
          ></option>
          <option
            children="Working Equitation"
            value="Working Equitation"
            {...getOverrideProps(overrides, "disciplineoption12")}
          ></option>
        </SelectField>
        <SelectField
          label="Age Group"
          descriptiveText=""
          placeholder="Please select an option"
          isDisabled={false}
          value={ageGroup}
          onChange={(e) => {
            let { value } = e.target;
            if (onChange) {
              const modelFields = {
                riderFullName,
                horseName,
                competition,
                date,
                discipline,
                ageGroup: value,
                class: class1,
                eventScoreTime,
                result,
                Field0,
              };
              const result = onChange(modelFields);
              value = result?.ageGroup ?? value;
            }
            if (errors.ageGroup?.hasError) {
              runValidationTasks("ageGroup", value);
            }
            setAgeGroup(value);
          }}
          onBlur={() => runValidationTasks("ageGroup", ageGroup)}
          errorMessage={errors.ageGroup?.errorMessage}
          hasError={errors.ageGroup?.hasError}
          {...getOverrideProps(overrides, "ageGroup")}
        >
          <option
            children="Leadline (Under 10)"
            value="Leadline (Under 10)"
            {...getOverrideProps(overrides, "ageGroupoption0")}
          ></option>
          <option
            children="Junior (Under 17)"
            value="Junior (Under 17)"
            {...getOverrideProps(overrides, "ageGroupoption1")}
          ></option>
          <option
            children="Associate (17-24)"
            value="Associate (17-24)"
            {...getOverrideProps(overrides, "ageGroupoption2")}
          ></option>
          <option
            children="Master (Over 25)"
            value="Master (Over 25)"
            {...getOverrideProps(overrides, "ageGroupoption3")}
          ></option>
        </SelectField>
      </Grid>
      <TextField
        label="Class / Event"
        descriptiveText="E.g. 'Prelim 1.1'"
        isRequired={true}
        isReadOnly={false}
        value={class1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName,
              competition,
              date,
              discipline,
              ageGroup,
              class: value,
              eventScoreTime,
              result,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.class ?? value;
          }
          if (errors.class?.hasError) {
            runValidationTasks("class", value);
          }
          setClass1(value);
        }}
        onBlur={() => runValidationTasks("class", class1)}
        errorMessage={errors.class?.errorMessage}
        hasError={errors.class?.hasError}
        {...getOverrideProps(overrides, "class")}
      ></TextField>
      <TextAreaField
        label="Score / Time / Percentage"
        descriptiveText="Optional based on discipline"
        isRequired={false}
        isReadOnly={false}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName,
              competition,
              date,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime: value,
              result,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.eventScoreTime ?? value;
          }
          if (errors.eventScoreTime?.hasError) {
            runValidationTasks("eventScoreTime", value);
          }
          setEventScoreTime(value);
        }}
        onBlur={() => runValidationTasks("eventScoreTime", eventScoreTime)}
        errorMessage={errors.eventScoreTime?.errorMessage}
        hasError={errors.eventScoreTime?.hasError}
        {...getOverrideProps(overrides, "eventScoreTime")}
      ></TextAreaField>
      <SelectField
        label="Result"
        descriptiveText=""
        placeholder="Please select an option"
        isDisabled={false}
        value={result}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName,
              competition,
              date,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime,
              result: value,
              Field0,
            };
            const result = onChange(modelFields);
            value = result?.result ?? value;
          }
          if (errors.result?.hasError) {
            runValidationTasks("result", value);
          }
          setResult(value);
        }}
        onBlur={() => runValidationTasks("result", result)}
        errorMessage={errors.result?.errorMessage}
        hasError={errors.result?.hasError}
        {...getOverrideProps(overrides, "result")}
      >
        <option
          children="1st"
          value="1st"
          {...getOverrideProps(overrides, "resultoption0")}
        ></option>
        <option
          children="2nd"
          value="2nd"
          {...getOverrideProps(overrides, "resultoption1")}
        ></option>
        <option
          children="3rd"
          value="3rd"
          {...getOverrideProps(overrides, "resultoption2")}
        ></option>
        <option
          children="4th"
          value="4th"
          {...getOverrideProps(overrides, "resultoption3")}
        ></option>
        <option
          children="5th"
          value="5th"
          {...getOverrideProps(overrides, "resultoption4")}
        ></option>
        <option
          children="6th"
          value="6th"
          {...getOverrideProps(overrides, "resultoption5")}
        ></option>
        <option
          children="7th"
          value="7th"
          {...getOverrideProps(overrides, "resultoption6")}
        ></option>
        <option
          children="8th"
          value="8th"
          {...getOverrideProps(overrides, "resultoption7")}
        ></option>
        <option
          children="9th"
          value="9th"
          {...getOverrideProps(overrides, "resultoption8")}
        ></option>
        <option
          children="10th"
          value="10th"
          {...getOverrideProps(overrides, "resultoption9")}
        ></option>
      </SelectField>
      <CheckboxField
        label="I agree to be honest and truthful about the results I am submitting"
        name="Field0"
        value="Field0"
        isDisabled={false}
        checked={Field0}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              riderFullName,
              horseName,
              competition,
              date,
              discipline,
              ageGroup,
              class: class1,
              eventScoreTime,
              result,
              Field0: value,
            };
            const result = onChange(modelFields);
            value = result?.Field0 ?? value;
          }
          if (errors.Field0?.hasError) {
            runValidationTasks("Field0", value);
          }
          setField0(value);
        }}
        onBlur={() => runValidationTasks("Field0", Field0)}
        errorMessage={errors.Field0?.errorMessage}
        hasError={errors.Field0?.hasError}
        {...getOverrideProps(overrides, "Field0")}
      ></CheckboxField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Clear"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          {...getOverrideProps(overrides, "ClearButton")}
        ></Button>
        <Flex
          gap={tokens.space.medium.value}
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={Object.values(errors).some((e) => e?.hasError)}
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
