import React from 'react';

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center', padding: '20px', marginTop: '20px', fontSize: '14px'}}>
      <b>Copyright © 2024 Rosewood Hack & Pony Club</b>
    </footer>
  );
};

export default Footer;