/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  Button,
  Flex,
  Grid,
  SwitchField,
  TextField,
} from "@aws-amplify/ui-react";
import { fetchByPath, getOverrideProps, validateField } from "./utils";
import { generateClient } from "aws-amplify/api";
import { getOverallResults } from "../graphql/queries";
import { updateOverallResults } from "../graphql/mutations";
const client = generateClient();
export default function OverallResultsUpdateForm(props) {
  const {
    id: idProp,
    overallResults: overallResultsModelProp,
    onSuccess,
    onError,
    onSubmit,
    onValidate,
    onChange,
    overrides,
    ...rest
  } = props;
  const initialValues = {
    riderFullName: "",
    date: "",
    result: "",
    discipline: "",
    class: "",
    ageGroup: "",
    Field0: false,
    eventScoreTime: "",
    competition: "",
  };
  const [riderFullName, setRiderFullName] = React.useState(
    initialValues.riderFullName
  );
  const [date, setDate] = React.useState(initialValues.date);
  const [result, setResult] = React.useState(initialValues.result);
  const [discipline, setDiscipline] = React.useState(initialValues.discipline);
  const [class1, setClass1] = React.useState(initialValues.class);
  const [ageGroup, setAgeGroup] = React.useState(initialValues.ageGroup);
  const [Field0, setField0] = React.useState(initialValues.Field0);
  const [eventScoreTime, setEventScoreTime] = React.useState(
    initialValues.eventScoreTime
  );
  const [competition, setCompetition] = React.useState(
    initialValues.competition
  );
  const [errors, setErrors] = React.useState({});
  const resetStateValues = () => {
    const cleanValues = overallResultsRecord
      ? { ...initialValues, ...overallResultsRecord }
      : initialValues;
    setRiderFullName(cleanValues.riderFullName);
    setDate(cleanValues.date);
    setResult(cleanValues.result);
    setDiscipline(cleanValues.discipline);
    setClass1(cleanValues.class);
    setAgeGroup(cleanValues.ageGroup);
    setField0(cleanValues.Field0);
    setEventScoreTime(cleanValues.eventScoreTime);
    setCompetition(cleanValues.competition);
    setErrors({});
  };
  const [overallResultsRecord, setOverallResultsRecord] = React.useState(
    overallResultsModelProp
  );
  React.useEffect(() => {
    const queryData = async () => {
      const record = idProp
        ? (
            await client.graphql({
              query: getOverallResults.replaceAll("__typename", ""),
              variables: { id: idProp },
            })
          )?.data?.getOverallResults
        : overallResultsModelProp;
      setOverallResultsRecord(record);
    };
    queryData();
  }, [idProp, overallResultsModelProp]);
  React.useEffect(resetStateValues, [overallResultsRecord]);
  const validations = {
    riderFullName: [],
    date: [],
    result: [],
    discipline: [],
    class: [],
    ageGroup: [],
    Field0: [],
    eventScoreTime: [],
    competition: [],
  };
  const runValidationTasks = async (
    fieldName,
    currentValue,
    getDisplayValue
  ) => {
    const value =
      currentValue && getDisplayValue
        ? getDisplayValue(currentValue)
        : currentValue;
    let validationResponse = validateField(value, validations[fieldName]);
    const customValidator = fetchByPath(onValidate, fieldName);
    if (customValidator) {
      validationResponse = await customValidator(value, validationResponse);
    }
    setErrors((errors) => ({ ...errors, [fieldName]: validationResponse }));
    return validationResponse;
  };
  return (
    <Grid
      as="form"
      rowGap="15px"
      columnGap="15px"
      padding="20px"
      onSubmit={async (event) => {
        event.preventDefault();
        let modelFields = {
          riderFullName: riderFullName ?? null,
          date: date ?? null,
          result: result ?? null,
          discipline: discipline ?? null,
          class: class {} ?? null,
          ageGroup: ageGroup ?? null,
          Field0: Field0 ?? null,
          eventScoreTime: eventScoreTime ?? null,
          competition: competition ?? null,
        };
        const validationResponses = await Promise.all(
          Object.keys(validations).reduce((promises, fieldName) => {
            if (Array.isArray(modelFields[fieldName])) {
              promises.push(
                ...modelFields[fieldName].map((item) =>
                  runValidationTasks(fieldName, item)
                )
              );
              return promises;
            }
            promises.push(
              runValidationTasks(fieldName, modelFields[fieldName])
            );
            return promises;
          }, [])
        );
        if (validationResponses.some((r) => r.hasError)) {
          return;
        }
        if (onSubmit) {
          modelFields = onSubmit(modelFields);
        }
        try {
          Object.entries(modelFields).forEach(([key, value]) => {
            if (typeof value === "string" && value === "") {
              modelFields[key] = null;
            }
          });
          await client.graphql({
            query: updateOverallResults.replaceAll("__typename", ""),
            variables: {
              input: {
                id: overallResultsRecord.id,
                ...modelFields,
              },
            },
          });
          if (onSuccess) {
            onSuccess(modelFields);
          }
        } catch (err) {
          if (onError) {
            const messages = err.errors.map((e) => e.message).join("\n");
            onError(modelFields, messages);
          }
        }
      }}
      {...getOverrideProps(overrides, "OverallResultsUpdateForm")}
      {...rest}
    >
      <TextField
        label="Rider full name"
        isRequired={false}
        isReadOnly={false}
        value={riderFullName}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName: value,
              date,
              result,
              discipline,
              class: class1,
              ageGroup,
              Field0,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.riderFullName ?? value;
          }
          if (errors.riderFullName?.hasError) {
            runValidationTasks("riderFullName", value);
          }
          setRiderFullName(value);
        }}
        onBlur={() => runValidationTasks("riderFullName", riderFullName)}
        errorMessage={errors.riderFullName?.errorMessage}
        hasError={errors.riderFullName?.hasError}
        {...getOverrideProps(overrides, "riderFullName")}
      ></TextField>
      <TextField
        label="Date of Competition"
        isRequired={false}
        isReadOnly={false}
        type="date"
        value={date}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date: value,
              result,
              discipline,
              class: class1,
              ageGroup,
              Field0,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.date ?? value;
          }
          if (errors.date?.hasError) {
            runValidationTasks("date", value);
          }
          setDate(value);
        }}
        onBlur={() => runValidationTasks("date", date)}
        errorMessage={errors.date?.errorMessage}
        hasError={errors.date?.hasError}
        {...getOverrideProps(overrides, "date")}
      ></TextField>
      <TextField
        label="Result"
        descriptiveText="Enter result as '1st' '2nd' '3rd' '4th' etc."
        isRequired={false}
        isReadOnly={false}
        value={result}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result: value,
              discipline,
              class: class1,
              ageGroup,
              Field0,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.result ?? value;
          }
          if (errors.result?.hasError) {
            runValidationTasks("result", value);
          }
          setResult(value);
        }}
        onBlur={() => runValidationTasks("result", result)}
        errorMessage={errors.result?.errorMessage}
        hasError={errors.result?.hasError}
        {...getOverrideProps(overrides, "result")}
      ></TextField>
      <TextField
        label="Discipline"
        isRequired={false}
        isReadOnly={false}
        value={discipline}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result,
              discipline: value,
              class: class1,
              ageGroup,
              Field0,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.discipline ?? value;
          }
          if (errors.discipline?.hasError) {
            runValidationTasks("discipline", value);
          }
          setDiscipline(value);
        }}
        onBlur={() => runValidationTasks("discipline", discipline)}
        errorMessage={errors.discipline?.errorMessage}
        hasError={errors.discipline?.hasError}
        {...getOverrideProps(overrides, "discipline")}
      ></TextField>
      <TextField
        label="Class"
        isRequired={false}
        isReadOnly={false}
        value={class1}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result,
              discipline,
              class: value,
              ageGroup,
              Field0,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.class ?? value;
          }
          if (errors.class?.hasError) {
            runValidationTasks("class", value);
          }
          setClass1(value);
        }}
        onBlur={() => runValidationTasks("class", class1)}
        errorMessage={errors.class?.errorMessage}
        hasError={errors.class?.hasError}
        {...getOverrideProps(overrides, "class")}
      ></TextField>
      <TextField
        label="Age group"
        isRequired={false}
        isReadOnly={false}
        value={ageGroup}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result,
              discipline,
              class: class1,
              ageGroup: value,
              Field0,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.ageGroup ?? value;
          }
          if (errors.ageGroup?.hasError) {
            runValidationTasks("ageGroup", value);
          }
          setAgeGroup(value);
        }}
        onBlur={() => runValidationTasks("ageGroup", ageGroup)}
        errorMessage={errors.ageGroup?.errorMessage}
        hasError={errors.ageGroup?.hasError}
        {...getOverrideProps(overrides, "ageGroup")}
      ></TextField>
      <SwitchField
        label="Field0"
        defaultChecked={false}
        isDisabled={false}
        isChecked={Field0}
        onChange={(e) => {
          let value = e.target.checked;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result,
              discipline,
              class: class1,
              ageGroup,
              Field0: value,
              eventScoreTime,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.Field0 ?? value;
          }
          if (errors.Field0?.hasError) {
            runValidationTasks("Field0", value);
          }
          setField0(value);
        }}
        onBlur={() => runValidationTasks("Field0", Field0)}
        errorMessage={errors.Field0?.errorMessage}
        hasError={errors.Field0?.hasError}
        {...getOverrideProps(overrides, "Field0")}
      ></SwitchField>
      <TextField
        label="Event score time"
        isRequired={false}
        isReadOnly={false}
        value={eventScoreTime}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result,
              discipline,
              class: class1,
              ageGroup,
              Field0,
              eventScoreTime: value,
              competition,
            };
            const result = onChange(modelFields);
            value = result?.eventScoreTime ?? value;
          }
          if (errors.eventScoreTime?.hasError) {
            runValidationTasks("eventScoreTime", value);
          }
          setEventScoreTime(value);
        }}
        onBlur={() => runValidationTasks("eventScoreTime", eventScoreTime)}
        errorMessage={errors.eventScoreTime?.errorMessage}
        hasError={errors.eventScoreTime?.hasError}
        {...getOverrideProps(overrides, "eventScoreTime")}
      ></TextField>
      <TextField
        label="Competition"
        isRequired={false}
        isReadOnly={false}
        value={competition}
        onChange={(e) => {
          let { value } = e.target;
          if (onChange) {
            const modelFields = {
              riderFullName,
              date,
              result,
              discipline,
              class: class1,
              ageGroup,
              Field0,
              eventScoreTime,
              competition: value,
            };
            const result = onChange(modelFields);
            value = result?.competition ?? value;
          }
          if (errors.competition?.hasError) {
            runValidationTasks("competition", value);
          }
          setCompetition(value);
        }}
        onBlur={() => runValidationTasks("competition", competition)}
        errorMessage={errors.competition?.errorMessage}
        hasError={errors.competition?.hasError}
        {...getOverrideProps(overrides, "competition")}
      ></TextField>
      <Flex
        justifyContent="space-between"
        {...getOverrideProps(overrides, "CTAFlex")}
      >
        <Button
          children="Reset"
          type="reset"
          onClick={(event) => {
            event.preventDefault();
            resetStateValues();
          }}
          isDisabled={!(idProp || overallResultsModelProp)}
          {...getOverrideProps(overrides, "ResetButton")}
        ></Button>
        <Flex
          gap="15px"
          {...getOverrideProps(overrides, "RightAlignCTASubFlex")}
        >
          <Button
            children="Submit"
            type="submit"
            variation="primary"
            isDisabled={
              !(idProp || overallResultsModelProp) ||
              Object.values(errors).some((e) => e?.hasError)
            }
            {...getOverrideProps(overrides, "SubmitButton")}
          ></Button>
        </Flex>
      </Flex>
    </Grid>
  );
}
