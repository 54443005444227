import React, { useState } from 'react';
import { OverallResultsCreateForm } from './ui-components';

const OverallResultsPage = () => {
  const [isBusy, setIsBusy] = useState(false);

  // No 'handleFormSubmit' function is used because we can't directly control the form submission

  const handleSubmitSuccess = () => {
    alert('Result submitted successfully!');
    setIsBusy(false);  // Stop showing the loading overlay after successful submission
  };

  // Fake a button to simulate starting the submission process
  const startSubmission = () => {
    setIsBusy(true);  // Assume the form will start submitting soon after button is clicked
    // Ideally, the form should have its own button that triggers the actual submission
  };

  return (
    <div>
      {isBusy && (
        <div style={overlayStyle}>
          Submitting...
        </div>
      )}
      <button onClick={startSubmission} style={{ display: "none" }}>Start Submission</button>
      <OverallResultsCreateForm onSuccess={handleSubmitSuccess} />
    </div>
  );
};

const overlayStyle = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '24px',
  zIndex: '1000'
};

export default OverallResultsPage;