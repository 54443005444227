import React, { useEffect, useState, useMemo } from 'react';
import { useTable, useSortBy, useFilters } from 'react-table';
import { generateClient } from 'aws-amplify/api';
import * as queries from './graphql/queries';
import './ResultsTable.css';

const ResultsTable = () => {
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState('');

  // Memorise the client to prevent re-creation on each render
  const client = useMemo(() => generateClient(), []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const resultData = await client.graphql({ query: queries.listOverallResults });
        setData(resultData.data.listOverallResults.items);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, [client]);  // client is now stable, so this effect runs only once

  // Filter data based on search input
  const filteredData = useMemo(() => data.filter(
    item => item.riderFullName.toLowerCase().includes(searchInput.toLowerCase())),
    [data, searchInput]
  );

  const columns = useMemo(
    () => [
      { Header: 'Rider', accessor: 'riderFullName' },
      { Header: 'Horse', accessor: 'horseName' },
      { Header: 'Competition', accessor: 'competition' },
      { Header: 'Date', accessor: 'date' },
      { Header: 'Discipline', accessor: 'discipline' },
      { Header: 'Class', accessor: 'class' },
      { Header: 'Score/Time/Percentage', accessor: 'eventScoreTime' },
      { Header: 'Result', accessor: 'result' },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data: filteredData }, useFilters, useSortBy);

  return (
    <>
      <input
        type="text"
        value={searchInput}
        onChange={e => setSearchInput(e.target.value)}
        placeholder="Search by Rider Name"
        className="search-input"
      />
      <div className="results-table-container">
        <table {...getTableProps()} className="table">
          <thead className="table-header">
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} className="th">
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? '' : '') : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="tr">
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="td">
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ResultsTable;