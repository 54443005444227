// MyAuthHeader.js
import React from 'react';
import { View, Heading } from '@aws-amplify/ui-react';

const MyAuthHeader = () => {
  return (
    <View textAlign="center" padding="20px">
      <Heading level={3} style={{ color: 'rgb(211, 33, 33)'}}>RHPC Results</Heading>
    </View>
  );
};

export default MyAuthHeader;