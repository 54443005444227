/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMusterAttendance = /* GraphQL */ `
  mutation CreateMusterAttendance(
    $input: CreateMusterAttendanceInput!
    $condition: ModelMusterAttendanceConditionInput
  ) {
    createMusterAttendance(input: $input, condition: $condition) {
      id
      riderFullName
      date
      horseHealthDec
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMusterAttendance = /* GraphQL */ `
  mutation UpdateMusterAttendance(
    $input: UpdateMusterAttendanceInput!
    $condition: ModelMusterAttendanceConditionInput
  ) {
    updateMusterAttendance(input: $input, condition: $condition) {
      id
      riderFullName
      date
      horseHealthDec
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMusterAttendance = /* GraphQL */ `
  mutation DeleteMusterAttendance(
    $input: DeleteMusterAttendanceInput!
    $condition: ModelMusterAttendanceConditionInput
  ) {
    deleteMusterAttendance(input: $input, condition: $condition) {
      id
      riderFullName
      date
      horseHealthDec
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createOverallResults = /* GraphQL */ `
  mutation CreateOverallResults(
    $input: CreateOverallResultsInput!
    $condition: ModelOverallResultsConditionInput
  ) {
    createOverallResults(input: $input, condition: $condition) {
      id
      riderFullName
      horseName
      date
      result
      discipline
      class
      ageGroup
      Field0
      eventScoreTime
      competition
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateOverallResults = /* GraphQL */ `
  mutation UpdateOverallResults(
    $input: UpdateOverallResultsInput!
    $condition: ModelOverallResultsConditionInput
  ) {
    updateOverallResults(input: $input, condition: $condition) {
      id
      riderFullName
      horseName
      date
      result
      discipline
      class
      ageGroup
      Field0
      eventScoreTime
      competition
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteOverallResults = /* GraphQL */ `
  mutation DeleteOverallResults(
    $input: DeleteOverallResultsInput!
    $condition: ModelOverallResultsConditionInput
  ) {
    deleteOverallResults(input: $input, condition: $condition) {
      id
      riderFullName
      horseName
      date
      result
      discipline
      class
      ageGroup
      Field0
      eventScoreTime
      competition
      createdAt
      updatedAt
      __typename
    }
  }
`;
