import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { withAuthenticator, Heading, View, Card, Flex, Image, Loader, Button } from '@aws-amplify/ui-react';
import "@aws-amplify/ui-react/styles.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Footer from './Footer';
import './App.css';
import OverallResultsPage from './OverallResultsForm';
import musterAttendancePage from './musterAttendanceForm';
import ResultsTable from './ResultsTable';
import MyAuthHeader from './MyAuthHeader'; // Import the custom header component

function App({ signOut, user }) {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
      window.scrollTo(0, 0);
    }, []);

    if (isLoading) {
      return (
        <View className="loader-container">
          <Loader width="10rem" height="10rem"/>
        </View>
      );
    }

    return (
      <Router>
        <div className="App">
          <Navbar bg="light" expand="lg" className="navbar">
            <Navbar.Brand href="/">RHPC Results</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link as={Link} to="/">Home</Nav.Link>
                <Nav.Link as={Link} to="/results-table">Results</Nav.Link>
                <Nav.Link as={Link} to="/submit-competition-results">Submit</Nav.Link>
                <Button onClick={signOut} variation="link" className="btn">Sign Out</Button>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Routes>
            <Route path="/" element={
              <View>
                <Card>
                  <Flex direction="column" alignItems="center" className="content">
                    <Heading className="content-heading">RHPC Results</Heading>
                    <p className="content-paragraph">Begin viewing or submitting results.</p>
                    <Flex direction="row" gap="10px" alignContent="center">
                      <Link to="/results-table">
                        <Button className='btntwo'>View Results</Button>
                      </Link>
                      <Link to="/submit-competition-results">
                        <Button className='btntwo'>Submit Results</Button>
                      </Link>
                    </Flex>
                  </Flex>
                </Card>
              </View>
            } />
            <Route path="/submit-competition-results" element={<OverallResultsPage />} />
            <Route path="/attendance" element={<musterAttendancePage />} />
            <Route path="/results-table" element={<ResultsTable />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    );
}

export default withAuthenticator(App, { components: { Header: MyAuthHeader } });