/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMusterAttendance = /* GraphQL */ `
  query GetMusterAttendance($id: ID!) {
    getMusterAttendance(id: $id) {
      id
      riderFullName
      date
      horseHealthDec
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listMusterAttendances = /* GraphQL */ `
  query ListMusterAttendances(
    $filter: ModelMusterAttendanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMusterAttendances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        riderFullName
        date
        horseHealthDec
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getOverallResults = /* GraphQL */ `
  query GetOverallResults($id: ID!) {
    getOverallResults(id: $id) {
      id
      riderFullName
      horseName
      date
      result
      discipline
      class
      ageGroup
      Field0
      eventScoreTime
      competition
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listOverallResults = /* GraphQL */ `
  query ListOverallResults(
    $filter: ModelOverallResultsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOverallResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        riderFullName
        horseName
        date
        result
        discipline
        class
        ageGroup
        Field0
        eventScoreTime
        competition
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
